import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
/**
 * Represents Services Detail Component.
 * @author Harmeet
 */import React from 'react';
import styled from 'styled-components';
import styles from './ServiceDetail.style';
import Heading from '../../atoms/Heading';
import Carousel from '../../molecules/Carousel';
import Image from '../../atoms/Image';
import RichText from '../../atoms/RichText/RichText';
import CarouselArrow from '../../atoms/CarouselArrow';
import { imageRenditions } from './config';
var DetailsSection = styled.section.withConfig({
  componentId: "sc-iwjyx1-0"
})(["", ";"], styles);
var ServiceDetail = function ServiceDetail(_ref) {
  var serviceDetailsDto = _ref.serviceDetailsDto;
  if (!serviceDetailsDto) {
    return null;
  }
  var carouselSettings = {
    infinite: true,
    slidesToShow: 1,
    rows: 1,
    slidesPerRow: 1,
    arrows: true,
    speed: 500,
    dots: true,
    slidesToScroll: 1,
    prevArrow: __jsx(CarouselArrow, {
      type: "previous"
    }),
    nextArrow: __jsx(CarouselArrow, {
      type: "next"
    })
  };
  var serviceDetailData = serviceDetailsDto.serviceDetails;
  var localCopyData = serviceDetailsDto.localCopy;
  if (!serviceDetailData) {
    return null;
  }
  var localCopyEmbeddedAsset = localCopyData && localCopyData.links && localCopyData.links.assets.block || {};
  var serviceDetailEmbeddedAsset = serviceDetailData.serviceDetails && serviceDetailData.serviceDetails.links && serviceDetailData.serviceDetails.links.assets && serviceDetailData.serviceDetails.links.assets.block || {};
  var serviceOverviewEmbeddedAsset = serviceDetailData && serviceDetailData.serviceOverview && serviceDetailData.serviceOverview.links && serviceDetailData.serviceOverview.links.assets && serviceDetailData.serviceOverview.links.assets.block || {};
  return __jsx("div", {
    className: "service-detail"
  }, serviceDetailData && __jsx(DetailsSection, {
    className: "serviceDetail"
  }, __jsx("div", {
    className: "container"
  }, serviceDetailData.serviceName && __jsx("div", {
    className: "serviceName"
  }, __jsx(Heading, {
    HeadingType: "h1",
    className: "no-bar"
  }, serviceDetailData.serviceName)), serviceDetailData && serviceDetailData.serviceOverview && serviceDetailData.serviceOverview.json && __jsx(RichText, {
    className: "serviceOverview",
    jsonContent: serviceDetailData.serviceOverview.json,
    richTextEmbeddedAsset: serviceOverviewEmbeddedAsset
  }), serviceDetailData && serviceDetailData.galleryCollection && __jsx("div", {
    className: "serviceGallery"
  }, __jsx(Carousel, carouselSettings, serviceDetailData.galleryCollection.items.map(function (imgData, index) {
    var imageSettings = {
      src: imgData.image.url,
      placeholderSrc: imgData.image.url,
      alt: imgData.altText,
      title: imgData.title || imgData.altText
    };
    return __jsx(Image, _extends({
      key: index
    }, imageSettings, {
      srcSetSettingImg: {
        imageRenditions: imageRenditions
      },
      mobileImageUrl: imgData.mobileImage && imgData.mobileImage.url || imgData.image && imgData.image.url
    }));
  }))), __jsx("div", {
    className: "serviceDetails"
  }, serviceDetailData && serviceDetailData.serviceDetails && serviceDetailData.serviceDetails.json && __jsx("div", {
    className: "serviceDetailsClass"
  }, __jsx(RichText, {
    jsonContent: serviceDetailData.serviceDetails.json,
    richTextEmbeddedAsset: serviceDetailEmbeddedAsset
  })), localCopyData && __jsx("div", {
    className: "localCopyClass"
  }, __jsx(RichText, {
    jsonContent: localCopyData.json,
    richTextEmbeddedAsset: localCopyEmbeddedAsset
  }))))));
};
ServiceDetail.defaultProps = {
  className: ''
};
export default styled(ServiceDetail).withConfig({
  componentId: "sc-iwjyx1-1"
})(["", ";"], styles);