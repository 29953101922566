import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "blogArticleData", "pageRoute"];
var __jsx = React.createElement;
/**
 * Represents BlogArticle.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';
import RichText from '../../atoms/RichText/RichText';
import styles from './BlogArticle.style';
import { imageRenditions } from './config';
var BlogArticleDetails = function BlogArticleDetails(_ref) {
  var className = _ref.className,
    blogArticleData = _ref.blogArticleData,
    pageRoute = _ref.pageRoute,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!blogArticleData) {
    return null;
  }
  var title = blogArticleData.title,
    blogCategory = blogArticleData.blogCategory,
    blogImage = blogArticleData.blogImage,
    blogContent = blogArticleData.blogContent;
  var imageSource = blogImage && blogImage.image && blogImage.image.url;
  var content = blogContent && blogContent.json;
  var richTextEmbeddedAsset = blogContent.links && blogContent.links.assets.block || {};
  return __jsx("div", {
    className: className
  }, __jsx("div", {
    className: "container"
  }, __jsx(Heading, {
    HeadingType: "h1"
  }, title), __jsx(Heading, {
    HeadingType: "h4"
  }, blogCategory.categoryName), __jsx("div", {
    className: "image-wrapper"
  }, __jsx(Image, {
    alt: blogImage.altText,
    title: blogImage.title || blogImage.altText,
    src: imageSource,
    srcSetSettingImg: {
      imageRenditions: imageRenditions
    }
  })), __jsx(RichText, {
    jsonContent: content,
    richTextEmbeddedAsset: richTextEmbeddedAsset,
    className: "blog-content"
  })));
};
BlogArticleDetails.defaultProps = {
  className: ''
};
export default styled(BlogArticleDetails).withConfig({
  componentId: "sc-1vyrxm2-0"
})(["", ";"], styles);