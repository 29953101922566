import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { imageRenditions } from './config';
var Styles = css(["background-color:", ";.back-link{display:none;}.backLink{display:none;}.serviceName{padding:", ";@media (min-width:", "){padding:40px 0;h1{font-size:", ";line-height:", ";}}@media (min-width:", ") and (max-width:", "){padding:", ";}}hr{background-color:", ";margin:30px 20px 40px;@media (min-width:", "){margin:40px 114px;}@media (min-width:", "){margin:40px 0;}}.serviceGallery{margin-bottom:", ";@media (min-width:", ") and (max-width:", "){padding:0 114px;}}.slick-dots{bottom:-45px;}.slick-arrow{width:39px;height:78px;}.serviceOverview{padding:", ";@media (min-width:", "){padding:", ";}@media (min-width:", ") and (max-width:", "){padding:0 114px;}}.serviceDetails{padding:26px 20px 40px;ul{margin-bottom:1.45rem;}h2{margin:0;padding-bottom:20px;}@media (min-width:", ") and (max-width:", "){padding:24px 114px;}@media (min-width:", "){padding:", ";}}.slick-slide{overflow:hidden;", " ", "}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.service.bgColor;
}, function (props) {
  var _props$theme2;
  return ((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.service.serviceNamePadding) || '0 20px';
}, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontSizeH1;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.lineHeightH1;
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$theme5;
  return ((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.service.serviceNameMobilePadding) || '0 114px';
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.service.borderColor;
}, breakPoints.tabletP, breakPoints.desktop, function (props) {
  var _props$theme7;
  return ((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.service.serviceGalleryMarginBottom) || '40px';
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$theme8;
  return ((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.service.serviceOverviewPadding) || '0 20px 40px';
}, breakPoints.desktop, function (props) {
  var _props$theme9;
  return ((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.service.serviceOverviewMobilePadding) || '0 218px 40px';
}, breakPoints.tabletP, breakPoints.tabletL, breakPoints.tabletP, breakPoints.tabletL, breakPoints.desktop, function (props) {
  var _props$theme10;
  return ((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.service.serviceDetailsPadding) || '24px 218px 60px';
}, function (props) {
  var _props$theme11;
  return (_props$theme11 = props.theme) !== null && _props$theme11 !== void 0 && _props$theme11.service.cardRadius ? "border-radius: 10px;" : '';
}, function (props) {
  var _props$theme12;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && _props$theme12.service.addSidePaddingToServiceDetailImageOnMobile ? "@media (max-width: ".concat(breakPoints.mobile, ") {\n      padding: 0 15px;\n    }") : '';
});
export default Styles;