/**
 * @file Manages styles for Contact Us.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export default css(["padding:0 20px;background-color:", ";.back-link{display:none;}h1{margin-bottom:30px;margin-top:15px;color:", ";}h4{margin-bottom:16px;color:", ";@media (min-width:", "){margin-bottom:32px;}@media (min-width:", "){margin-bottom:60px;}}p{color:", ";}.image-wrapper{margin-bottom:25px;overflow:hidden;", ";}.blog-content{padding-bottom:30px;p:last-child{margin-bottom:0;}@media (min-width:", "){padding-bottom:80px;}@media (min-width:", "){padding:0 110px 80px;}}@media (min-width:", "){padding:0 115px;}@media (min-width:", "){padding:0;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.blogList.bgColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.blogList.headingColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.blogList.subHeadingColor;
}, breakPoints.tabletP, breakPoints.desktop, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.blogList.paraColor;
}, function (props) {
  var _props$theme5, _props$theme6;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.blogList.blogTileImageRadius ? "border-radius: ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.blogList.blogTileImageRadius, ";") : '';
}, breakPoints.desktop, breakPoints.desktopM, breakPoints.tabletP, breakPoints.desktop);